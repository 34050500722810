import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { ActivityIndicator } from '../components/generic'

const NotFoundPage = () => {
  if (typeof window === 'undefined') {
    return <ActivityIndicator />
  }

  if (typeof window !== 'undefined' && window && window.location.pathname.includes("private")) {  
    return <ActivityIndicator />
  }

  return <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
}

export default NotFoundPage
